export const environment = {
  production: false,
  platformApiUrl: 'https://api.appdevus.platform.navify.com', // Navify Platform URL
  authUiUrl: 'https://roche.authapp.appdevus.platform.navify.com', // Authentication application URL
  appAlias: 'xcapdev', // Client application alias
  tenantAlias: 'xcap-dev',
  apiProfile: '/api/v1/users/whoami',
  apiLogout: '/api/v1/auth/logout',
  catalogQueryUrl:
    'https://bvjhmvsbnbfrjlvnbtaylc65pu.appsync-api.us-east-1.amazonaws.com/graphql',

  config: {
    orgKey: 'imCORE',
    domain: 'https://api.starcap-dev.platform.navify.com/',
    domain2: 'https://api.starcap-dev.platform.navify.com/',
    roles: {
      XCAP_PLATFORM_ADMIN: 'XCAP_PLATFORM_ADMIN',
      starcap_platform_admin: 'starcap platform admin',
      imcore_scientific_director: 'imcore scientific director',
      imcore_member: 'imcore member',
      imcore_member_data_ingress_only: 'imcore member(data ingress only)',
      imcore_guest: 'imcore guest',
      data_controller: 'data controller',
      site_dpo_gpo: 'site dpo/gpo',
      inspector_gadget: 'inspector gadget',
      starCap_Platform_Admin: 'starCAP Platform Admin',
      roleIds: {
        platformAdmin: 'PLATFORM_ADMIN',
      },
      roleTypes: {
        org: 'ORGANIZATION',
        proj: 'PROJECT',
        platform: 'PLATFORM',
      },
    },
    navifyRoles: {
      alias: 'xcap-dev',
      starcap: 'starcap',
    },
  },

  //User Management
  userDetails: 'user-management-services/api/v1/user/listUsersRoles',
  inviteUser: 'user-management-services/api/v1/user/create',
  getTermsCondition: 'user-management-services/api/v1/user/gettc',
  updateTermsCondition: 'user-management-services/api/v1/user/updatetc',
  approve: 'user-management-services/api/v1/user/approve',
  userList: 'user-management-services/api/v1/user/listUsers',
  addCollaborator: 'user-management-services/api/v1/user/inviteUser',
  updateUserDetails: 'user-management-services/api/v1/user/updateUserDetails',
  dataClassification:
    'user-management-services/api/v1/platformconfig/getdata/ApprovalDC',
  manageDataController: 'user-management-services/api/v1/user/updateDCRole',
  cadenceDataUrl:
    'user-management-services/api/v1/platformconfig/getdata/auditcadence',
  dataModalities:
    'user-management-services/api/v1/platformconfig/getdata/dataModalities',

  //Organization Management
  getOrganization: 'org-management-services/api/v1/organisation/getOrg',
  organizationOverview: 'org-management-services/api/v1/organisation/list',

  //Discourse
  discourseApiLink: 'org-management-services/api/v1/organisation/discourseUrl',

  // Data Catalog
  dataCatalogOverview:
    'data-catalog-services/api/v1/datacatalog/catalogOverview',

  //User Management
  userOverview: 'user-management-services/api/v1/user/getUserList',
  userProjectdetails: 'user-management-services/api/v1/user/getUserProjects',
  adminroleaccess: 'user-management-services/api/v1/user/getadminroleaccess',

  // Common Login & Role
  orgRole: 'login-services/api/v1/login',
  permissionRoles:
    'org-management-services/api/v1/organisation/getRolePermissions',
  currentRole: 'user-management-services/api/v1/user/getroleaccess?userId=',

  // Project Management
  projectDetails: 'project-management-services/api/v1/project/getproject',
  projCreate: 'project-management-services/api/v1/project/create',
  projEdit: 'project-management-services/api/v1/project/updateproject',
  projList: 'project-management-services/api/v1/project/projectOverview',
  instanceList: 'project-management-services/api/v1/project/getinstancelist',
  getSagemakerUserProfile:
    'project-management-services/api/v1/project/getsagemakeruserprofile',
  getVsCodeCredentials:
    'project-management-services/api/v1/project/getcloudninecredentials',
  getSagemakerPresignedUrl:
    'project-management-services/api/v1/project/getsagemakerpresignedurl',
  jupyterPreSignedUrl:
    'project-management-services/api/v1/project/getjupyterpresignedurl',
  ec2PreSignedUrl:
    'project-management-services/api/v1/project/getec2presignedurl',
  getCloudnineinstance:
    'project-management-services/api/v1/project/getcloudnineinstance',
  createCloudnineInstance:
    'project-management-services/api/v1/project/createcloudnine',
  getCloudnineFederatedUrl:
    'project-management-services/api/v1/project/generatefederatedurl',
  terminateInstance:
    'project-management-services/api/v1/project/deleteinstance',
  projUsers: 'user-management-services/api/v1/user/listUsers/PROJECT1/imCORE',
  projRegion: 'user-management-services/api/v1/platformconfig/getdata/region',
  jupyterSizes:
    'user-management-services/api/v1/platformconfig/getdata/jupyterInstanceSize',
  ec2Sizes:
    'user-management-services/api/v1/platformconfig/getdata/ec2InstanceSize',
  projClassification:
    'user-management-services/api/v1/platformconfig/getdata/dataclassification',
  allRoles: 'org-management-services/api/v1/organisation/getprojectroles',
  inviteCollaborators:
    'user-management-services/api/v1/user/invitecollaborators',
  searchUserList: 'user-management-services/api/v1/user/listusertocollaborate',
  projCollabList: 'project-management-services/api/v1/project/projectOverview',
  collaboratorslist: 'user-management-services/api/v1/user/listUsers',
  editCollaborators: 'user-management-services/api/v1/user/updateCollaborator',
  deleteCollabUsers:
    'user-management-services/api/v1/user/deleteUserfromProject',
  getProjectRole:
    'user-management-services/api/v1/user/getprojectroledetails?userId=',
  sites: 'user-management-services/api/v1/user/sites',
  dieasesArea:
    'user-management-services/api/v1/platformconfig/getitem/disease_area',
  studyType:
    'user-management-services/api/v1/platformconfig/getitem/studytypes',
  duplicateProject:
    'project-management-services/api/v1/project/getduplicateproject',
  addProject: 'project-management-services/api/v1/project/createproject',
  fileDelete: 'project-management-services/api/v1/project/projectfiledelete',
  checkDCuser: 'user-management-services/api/v1/user/dcstatus',
  createJupyter:
    'project-management-services/api/v1/project/createjupyternotebook',
  createEc2Instance:
    'project-management-services/api/v1/project/createec2instance',
  createSagemakerUserProfile:
    'project-management-services/api/v1/project/createsagemakeruserprofile',
  fileDownload:
    'project-management-services/api/v1/project/projectfiledownload',
  transferuserList: 'user-management-services/api/v1/user/transferuserlist',
  transferData: 'project-management-services/api/v1/project/transfer',
  updateAuditSettings:
    'project-management-services/api/v1/project/updateauditsettings',
  checkProjectCount: 'project-management-services/api/v1/project/projectcount',
  getEcrDetails: 'project-management-services/api/v1/project/getccecrurl',
  getCollabCodeCommit: 'project-management-services/api/v1/project/collabcc',
  projectbucketdetails:
    'project-management-services/api/v1/project/projectbucketdetails',
  projectRules: 'project-management-services/api/v1/project/projectRules',
  getCodeArtifact:
    'project-management-services/api/v1/project/getcodeartifacturl',

  getHpcDetails:
    'project-management-services/api/v1/project/hpcinstancedetails',
  getHpcLauncherDetails:
    'project-management-services/api/v1/project/hpcpresignedurl',

  getPositConnectDetails: 'project-management-services/api/v1/project/positurl',

  // Data Ingress
  dataSetList: 'data-ingress-services/api/v1/dataingress/getdatasetlist',
  getdatasetlist: 'data-ingress-services/api/v1/dataingress/getdatasetlist',
  deleteProjectUsers:
    'user-management-services/api/v1/user/deleteUserfromProject',
  deleteOrgUsers: 'user-management-services/api/v1/user/deleteUserfromOrg',
  sitenames: 'user-management-services/api/v1/platformconfig/getdata/sitenames',
  getDatasetDetail:
    'data-ingress-services/api/v1/dataingress/getdataset?datasetId=',
  sFTPDetails:
    'project-management-services/api/v1/project/getresourcedetail?projectKey=',
  getDataFilelist:
    'data-ingress-services/api/v1/dataingress/listsftpfiles?projectKey=',
  createdDataset: 'data-ingress-services/api/v1/dataingress/createdataset',
  getUserByEmail: 'user-management-services/api/v1/user/getuserbyemail',

  // My Approvals
  myApprovalsList:
    'data-ingress-services/api/v1/dataingress/listapprovaldataset',
  approveDataset: 'data-ingress-services/api/v1/dataingress/approvedataset',
  getPwd: 'login-services/api/v1/gettemppassword',
  myapprovalDatasetDetails:
    'data-ingress-services/api/v1/dataingress/getdataset',

  // data catalog
  datasetDetails: 'data-catalog-services/api/v1/datacatalog/viewCatalog',
  justIngressedDatasetDetails:
    'data-ingress-services/api/v1/dataingress/getdataset',
  searchCatalog: 'data-catalog-services/api/v1/datacatalog/searchcatalog',
  filterProject: 'project-management-services/api/v1/project/projectfilterlist',
  viewProjectCatalog:
    'data-catalog-services/api/v1/datacatalog/viewprojectcatalog',
  filterProjectCatalog:
    'data-ingress-services/api/v1/dataingress/filterbyprojectandstatus',
  ingressedDataset: 'data-ingress-services/api/v1',
  publishedDataset: 'org-catalog-services/api/v1/datasetsbyproject',
  dataDownload: 'org-catalog-services/api/v1/datasets/datadownload',
  sharedDataset: 'org-catalog-services/api/v1/viewsharedfiles',
  workAnalyticsDataset: 'org-catalog-services/api/v1/viewprojectcatalogfiles',
  allAssetsAndFiles: 'org-catalog-services/api/v1/datasets/allassetsandfiles',
  archivedDataset:
    'data-catalog-services/api/v1/datacatalog/viewprojectcatalog',

  // data catalog - publish:
  dataCatalogPublish: 'org-catalog-services/api/v1',

  // File upload API for project creation files
  projectCreationFileUpload:
    'project-management-services/api/v1/project/fileuploadproject',
  projectCreationCompleteMultiPart:
    'project-management-services/api/v1/project/completeuploadproject',
  dataIngressFileUpload: 'project-management-services/api/v1/fileUpload',
  dataIngressCompleteMultiPart:
    'project-management-services/api/v1/completeupload',
  dataIngressAdditionalFileUpload:
    'project-management-services/api/v1/project/fileuploadproject',
  dataIngressAdditionalCompleteMultiPart:
    'project-management-services/api/v1/project/completeuploadproject',
  dataIngressFailedNotificationApi:
    'project-management-services/api/v1/uploadstatus',

  startAuditUrl: 'project-audit-services/api/v1/projectaudit/startaudit',
  submitAuditResult: 'project-audit-services/api/v1/projectaudit/auditresult',
  auditHistory: 'project-audit-services/api/v1/projectaudit/getAuditHistory',
  downloadProjectInfo:
    'project-management-services/api/v1/project/downloadprojectinfo',
  downloadDataSets: 'data-ingress-services/api/v1/dataingress/downloaddatasets',
  deleteAuditUsers:
    'user-management-services/api/v1/user/deleteUserfromProject/audit',
  nextFlowLogin: 'project-management-services/api/v1/project/login',

  //Onboard bulk users
  onboardBulkUsersFileUpload:
    'user-management-services/api/v1/user/bulkupload/upload',
  onboardBulkUsersFileDelete: 'user-management-services/api/v1/user/bulkupload',
  onboardBulkUsersFileSubmit:
    'user-management-services/api/v1/user/bulkupload/submit',
  getBulkUploadUserList: 'user-management-services/api/v1/user/bulkupload',
  onboardBulkUsersFinalSubmit:
    'user-management-services/api/v1/user/onboardusers',
  onboardBulkUsersFileFetch:
    'user-management-services/api/v1/user/onboardfiledetails',
};
