//@ts-nocheck
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { StorageService } from './storage.service';
import { Env } from '@navify-platform/env';
import { AuthConf, Auth, AuthSession, AuthLoginReturn, AuthLogoutReturn, AuthEventType, AuthLoginReason, AuthLogoutReason } from '@navify-platform/auth';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { IdleOptions, Idle, IdleEvent, IdleEventType } from '@navify-platform/idle';
import { i18n } from '../i18n.en';
import { BehaviorSubject } from 'rxjs';

const env = new Env({
  props: {
    platformApiUrl: environment.platformApiUrl,  // Navify Platform URL
    authUiUrl: environment.authUiUrl,       // Authentication application URL
    appAlias: environment.appAlias,        // Client application alias
    tenantAlias: environment.tenantAlias,     // Client tenant alias
  },
});

const authConf: AuthConf = {
  env,                                 // Env library instance
  sessionExpirationBeforeTime: 60,  // (1 min) How much time before expiration should the library emit the BEFORE_SESSION_EXPIRATION event (milliseconds)
  sessionPollingIntervalTime: 600,  // (10 mins) How often should the library send a polling request to check the existing session (milliseconds)
  sessionAutoRefresh: true,           // Flag deciding whether to refresh session automatically before session expiration
  redirectUrlUseHash: false,           // Flag deciding whether to use URL hash instead of query parameters
  identificationFormSkip: false,       // Flag deciding whether to force skip the identification step
  identificationFormSubmit: false,     // Flag deciding whether to submit the identification step if username is provided
  customPageTitle: 'Kamino',              // Page title customization HTML
  customBackgroundStyle: "url('${landingPage}') center no-repeat / cover",        // Background style customization CSS
  customHeader: '',                 // Header customization HTML
  customHeadline: '<strong>NAVIFY<sup>®</sup> Kamino</strong>',               // Headline customization HTML
  customCaption: '',                // Caption customization HTML
  customFooter: "<div class=''><a href='http://roche.com'>Roche</a></div>",                 // Footer customization HTML
  i18n: false,                         // Flag deciding whethet to internationalize the UI
};


const idleOptions: IdleOptions = {
  env,                             // environment, NavifyPlatformEnv instance
  syncTimeInterval: Number('1000') * Number('60') * Number('10'),     // clock sync interval time (optional)
  tickTimeInterval: Number('1000') * Number('60') * Number('1'),          // clock tick interval time (optional)
  idleTimeout: Number('1000') * Number('60') * Number('59'),           // Idle timeout value override (optional)
  beforeIdleTimeSpan: Number('1000') * Number('60') * Number('1'),        // how much time before idle the event is triggered (optional)

  toggleSyncActivity: true,             // flag deciding whether to synchronize activity information via API
  toggleTracking: true,                 // flag deciding whether to turn on DOM events tracking
};

export const auth: Auth = new Auth(authConf);
export const idle = new Idle(idleOptions);

@Injectable({
  providedIn: 'root',
})
export class NavifyauthService {
  i18n = i18n;
  unsubs;
  showConfirmWindow = false;
  userRole: string = '';
  orgRole: string = '';
  trianingStatus: string = '';
  status: string = '';
  tcAccepted: boolean = false;
  isNavifyRoleExist: string = '';
  valueSet;
  roleSet;
  arrCont: string[] = [];
  arrYesNoCont: [] = [];
  isPlatformAdmin!: boolean;

  sessionCheckIntervalId!: number;
  sessionCheckInterval: number = 60000;

  readonly isIdle = new BehaviorSubject<boolean>(false);
  $isIdle = this.isIdle.asObservable();

  constructor(
    readonly router: Router,
    readonly storageService: StorageService,
    readonly http: HttpClient,
    readonly commonService: CommonService
  ) {
    this.sessionCheckIntervalId = setInterval(
      () => this.checkSessionTimeout(),
      this.sessionCheckInterval
    );
  }

  async initiateIdle() {
    await idle.init();
    await auth.init();
    idle.subscribe((event: IdleEvent) => {
      if (event.data.isBeforeIdle) {
        this.arrCont.push('BeforeIdle');
        if (this.arrCont.length === 1) {
          this.sendIsisIdle(true);
        }
      }
    });
  }

  sendIsisIdle(visible: boolean) {
    this.isIdle.next(visible);
  }

  async confirmIdleAction(idleOut: string) {
    this.arrYesNoCont.push(idleOut);
    await auth.init();
    await idle.init();
    if (idleOut === 'idle_yes' && this.arrYesNoCont.length === 1) {
      await idle.markActivity();
    } else if (idleOut === 'idle_no' && this.arrYesNoCont.length === 1) {
      await this.logOut();
    }
  }

  async loginToNavify() {
    await auth.init();
    this.storageService.secureStorage.setItem('isNavifyRoleExist', 'false');

    const loginOutput: AuthLoginReturn = await auth.getLoginReturn();

    const session: AuthSession = await auth.getSession();

    if (loginOutput !== null && session !== null) {
      this.storageService.secureStorage.setItem('isLoggedIn', 'true');
      // Get UserId from whoami api
      this.getUserProfile();
      await this.getLoginResults();
      this.setLoginTime();
    } else {
      this.storageService.secureStorage.setItem(
        'alreadyLoggedIn',
        JSON.stringify('false')
      );
      await auth.loginRedirect({
        returnTo: window.location.href, // Login return URL
        username: '',
        state: {},
      });
    }
  }

  private setLoginTime() {
    const loginTime = Date.now();
    localStorage.setItem('loginTime', loginTime);
  }

  private checkSessionTimeout() {
    const loginTime = localStorage.getItem('loginTime');
    if (loginTime) {
      const hours = 12;
      const minutes = 60;
      const seconds = 60;
      const miliseconds = 1000;
      const currentTime = Date.now();
      const twelveHoursInMilliseconds = hours * minutes * seconds * miliseconds;
      if (currentTime - loginTime > twelveHoursInMilliseconds) {
        this.logOut();
      }
    }
  }

  async getLoginResults() {
    await auth.init();
    const session: AuthSession = await auth.getSession();
    this.storageService.secureStorage.setItem('profile', session?.profile);
    return session;
  }

  // For Login
  getUserProfile() {
    this.getProfile().subscribe({
      next: (data) => {
        // Get current user permissions
        for (const element of data.tenants) {
          if (
            element.alias === environment.config.navifyRoles.alias ||
            element.alias.includes(environment.config.navifyRoles.starcap)
          ) {
            this.storageService.secureStorage.setItem(
              'isNavifyRoleExist',
              'true'
            );

            this.storageService.secureStorage.setItem(
              'userUuid',
              data.userUuid
            );
            // Login API to get Current Role details
            this.getCurrentUserRole(data, element);
          }
        }
        // Checking if user exist from Kamino
        this.isNavifyRoleExist =
          this.storageService.secureStorage.getItem('isNavifyRoleExist');
        if (this.isNavifyRoleExist === 'false') {
          this.router.navigate([i18n.NAV.TC]);
        }
      },
      error: (err) => {
        this.router.navigate(['/login']);
      },
      complete: () => {},
    });
  }

  // For Current User Role
  async getCurrentUserRole(data, element) {
    this.commonService
      .getCurrentUserRole({ userUuid: data.userUuid })
      .subscribe({
        next: async (res) => {
          this.storageService.secureStorage.setItem('loginUserName', [
            res.result.firstName,
            res.result.lastName,
          ]);
          res.result.roleDetails[0].roleId ===
          environment.config.roles.roleIds.platformAdmin
            ? (this.isPlatformAdmin = true)
            : (this.isPlatformAdmin = false);
          await this.getCurrentUserPermissions(
            data.userUuid,
            this.isPlatformAdmin
          );
          this.orgRole =
            res['result']['roleDetails'][0]['roleName'].toLowerCase();
          this.trianingStatus =
            res['result']['roleDetails'][0]['trianingStatus'].toLowerCase();
          this.status = res['result']['status'].toLowerCase();
          this.storageService.secureStorage.setItem('status', this.status);
          this.tcAccepted = res['result']['tcAccepted'];
          const orgRoleData = [];
          const currentUserRoles = [];

          for (const i of res.result.roleDetails) {
            currentUserRoles.push(i.roleName);
            if (i.roleType === environment.config.roles.roleTypes.org) {
              orgRoleData.push(i);
            }
          }
          // check training status
          const trainingStatus = res.result.roleDetails.filter(
            (val) => val.trianingStatus === 'COMPLETED'
          );
          if (trainingStatus.length > 0) {
            this.storageService.secureStorage.setItem(
              'trianingStatus',
              'completed'
            );
          } else {
            this.storageService.secureStorage.setItem(
              'trianingStatus',
              'pending'
            );
          }

          sessionStorage.setItem(
            'currentUserRoles',
            JSON.stringify(currentUserRoles)
          );
          this.storageService.secureStorage.setItem(
            'currentUserRoles',
            currentUserRoles
          );
          this.storageService.secureStorage.setItem(
            'orgRoleId',
            orgRoleData[0] ? orgRoleData[0].roleId : ''
          ); // currentUserRoleIds
          this.storageService.secureStorage.setItem(
            'tcAccepted',
            this.tcAccepted
          );
          this.checkIfNavifyRoleExist(data, element);
          // end

          if (
            this.orgRole === environment.config.roles.starcap_platform_admin
          ) {
            this.router.navigate([i18n.NAV.LOBBY]);
          } else {
            this.router.navigate([i18n.NAV.TC]);
          }
        },
        error: (err) => {
          this.router.navigate([i18n.NAV.TC]);
        },
      });
  }

  // For Login
  async getCurrentUserPermissions(userUuid: string, isPlatformAdmin) {
    if (isPlatformAdmin) {
      await this.getAdminRoleAccess();
    } else {
      await this.getOtherUsersRoleAccess(userUuid);
    }
  }

  checkIfNavifyRoleExist(data, element) {
    // navify role
    for (const [key, valueSet] of Object.entries(data)) {
      this.valueSet = valueSet;
      if (key === 'tenants') {
        for (const item of this.valueSet) {
          if (item.alias === environment.config.navifyRoles.alias) {
            this.storageService.secureStorage.setItem(
              'isNavifyRoleExist',
              'true'
            );
          } else {
            this.storageService.secureStorage.setItem(
              'isNavifyRoleExist',
              'false'
            );
            this.router.navigate([i18n.NAV.TC]);
          }
        }
      }
    }
  }

  async getAdminRoleAccess() {
    return this.handleRoleAccess(this.commonService.getAdminRoleAccess());
  }

  async getOtherUsersRoleAccess(userUuid: string) {
    return this.handleRoleAccess(this.currentUserPermissions(userUuid));
  }

  private handleRoleAccess(observable: Observable<any>): Promise<boolean> {
    return new Promise((resolve, reject) => {
      observable.subscribe({
        next: (res) => {
          this.storageService.secureStorage.setItem('currentUserPermissions', res.result.DashboadPermissions);
          sessionStorage.setItem('projectPermissions', JSON.stringify(res?.result?.RoleDetails));
        },
        error: (err) => {
          if (err.status === Number(this.i18n.errors.UA)) {
            auth.loginRedirect({
              returnTo: window.location.href,
              state: {},
              reason: AuthLoginReason.notAuthorized
            });
          }
        },
        complete: () => {
          resolve(true);
        }
      });
    });
  }

  async logOut() {
    this.storageService.secureStorage.removeItem('profile');
    this.storageService.secureStorage.removeItem('alreadyLoggedIn');
    this.storageService.secureStorage.removeItem('trianingStatus');
    this.storageService.secureStorage.removeItem('currentUserRoles');
    this.storageService.secureStorage.removeItem('tcAccepted');
    this.storageService.secureStorage.removeItem('isNavifyRoleExist');
    this.storageService.secureStorage.setItem('tcAccepted', false);
    this.storageService.secureStorage.setItem('trianingStatus', null);
    await auth.destroy();
    await idle.destroy();
    this.storageService.secureStorage.setItem('isLoggedIn', 'false');
    this.storageService.secureStorage.clear();
    this.storageService.secureStorage.setItem(
      'alreadyLoggedIn',
      JSON.stringify('false')
    );
    localStorage.removeItem('loginTime');
    clearInterval(this.sessionCheckIntervalId);
    sessionStorage.clear();
    localStorage.clear();
    this.commonService.getLogout().subscribe({
      next: () => {
        this.router.navigate(['/login']);
      },
      error: () => {},
      complete: () => {},
    });
  }

  getProfile() {
    const url = environment.platformApiUrl + environment.apiProfile;
    return this.http.get(url);
  }

  currentUserPermissions(userId: string) {
    const url =
      environment.config.domain +
      environment.currentRole +
      userId +
      '&orgKey=' +
      environment.config.orgKey;
    return this.http.get(url);
  }

  async resetPwd(un: string) {
    await auth.init();
    await auth.loginRedirect({
      returnTo:
        'https://roche.authapp.appdevus.platform.navify.com/recover?authunm=' +
        un,
    });
  }
}
