export interface NavItems {
  name: string;
  icon: string;
  url: string;
  role: string;
}

export const NAV = [
  {
    name: 'Dashboard',
    icon: 'home',
    url: '/m/applobby',
    role: 'LandingPage',
  },
  {
    name: 'Virtual Labs',
    icon: 'project',
    url: '/m/projects',
    role: 'projectManagementApp',
  },
  {
    name: 'Organization',
    icon: 'business',
    url: '/m/organization',
    role: 'orgManagementApp',
  },
  {
    name: 'Users',
    icon: 'user_group',
    url: '/m/users',
    role: 'platformUserManagementApp', // Platfrom admin
  },
  {
    name: 'Users',
    icon: 'user_group',
    url: '/m/pousers/pousers',
    role: 'UsersApp', // Study users
  },
  {
    name: 'Study audit',
    icon: 'test_result',
    url: '/m/audit',
    role: 'performAudit',
  },
  {
    name: 'Inbox',
    icon: 'mail',
    url: '/m/inbox',
    role: 'inboxApp',
  },
  {
    name: 'Data',
    icon: 'book_magazine',
    url: '/m/datacatalog',
    role: 'DataCatalog',
  },
  {
    name:'Budget',
    icon: 'billing',
    url: '/m/budget',
    role: 'BudgetApp',
  }
];
