export const SP = 'Kamino';
export const NDF = 'No data found';
export const PUM = 'Users';
export const OM = 'Organization';
export const PM = 'Studies';
export const PD = 'Study Details';
export const DC = 'Data';
export const MA = 'My Approvals';
export const DSD = 'Dataset Details';
export const CD = 'Create Dataset';
export const PN = 'Study Name';
export const ON = 'Organization Name';
export const DCLAS = 'Data Classification';
export const DSN = 'Dataset Name';
export const DSDESC = 'Dataset Description';
export const AP = 'Add Study';
export const RPP = 'Rows per page';
export const DID = 'Dataset ID';
export const CB = 'Created By';
export const DCD = 'Date Created';
export const DING = 'Date Ingressed';
export const DMTY = 'Data Maturity';
export const NODF = 'No. of Data Files';
export const DSIZE = 'Dataset Size';
export const AP_ERR_TYPE_1 =
  'Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed';
export const AP_ERR_TYPE_2 =
  'Only alphabets and special character "-" are allowed';
export const AP_ERR_TYPE_3 =
  'Only alphanumeric and special characters "-" and "_" are allowed';
export const AP_ERR_TYPE_4 =
  'One or more files selected exceed the 0.5 GB file size limit. Please revisit your selection.';
export const AP_ERR_TYPE_5 =
  'Total file size of the upload exceeds the total file size limitation of 1 GB. Please revisit your selection.';
export const NSR = 'No search results';
export const NSRD =
  'Check your search for typos or try a different search term';
export const P_DESC = 'Study Description';
export const AP_INFO_1 = 'Guidelines to upload,';
export const AP_INFO_2 =
  '1. Maximum number of files allowed for upload 20 files';
export const AP_INFO_3 = '2. Size limit per file - 0.5 GB per file';
export const AP_INFO_4 = '3. Total attachments size - 1 GB';
export const AP_INFO_5 = '4. File types allowed for upload -';
export const AP_INFO_6 =
  '.doc, .docx, .txt, .pdf, .csv, .png, .jpeg, .xls, .xlsx, .ics (apple calendar), .msg (Outlook email message)';
export const AP_EMAIL_ERR = 'Provide valid email address';
export const APPROVE = 'Approve';
export const STYN = 'Study Name';
export const STYI = 'Study Information';
export const LAD = 'Last Audit Date';
export const DOCATRES = 'Document Audit Result';
export const DWNLOAD = 'Download';
export const ADD_EMAIL_TONOTIFY = 'Additional recipients to notify.';
export const START_DOCUMENT_RESULT = 'Start Audit & Document Audit Result';
export const MANAGE_MEMBERS = 'Manage MEMBERS';
export const SA = 'Study audit';
export const UN = 'Username';
export const UF = 'Uploaded Files';
export const CLOSE = 'Close';
export const IM = 'Invite Member';
export const IU = 'Invite User';
export const AUD_I = 'Audit Initiation date by the Site GPO/DPO';
export const CURR_AUD = 'Current Audit Cycle Begin Date';
export const CAD_AUD = 'Cadence for Audit';
export const NO_DAYS = 'Specify number of days';
export const ST_AUD = 'Start Audit';
export const MDC = 'Manage Data Controller';
export const ASGN = 'Assign';
export const AH = 'Audit History';
export const A_P = 'Audit Passed';
export const PWW = 'Passed with Warning';
export const CMTS = 'Comments';
export const EMAIL_20 = 'Only 20 email addresses are allowed';
export const VALID_EMAIL = 'Enter valid email address';
export const ATMTS = 'Attachments';
export const DRAG_DROP = 'Drag and Drop files here';
export const OR = 'or';
export const BROWSE_FILES = 'Browse for Files';
export const MAX_20 = 'Maximum number of files allowed for upload: 20 Files.';
export const SZ_LIMIT = 'Size limit per file: 0.5 GB per file.';
export const ATMTS_SZ = 'Total attachments size - 1 GB.';
export const FILE_TYPE =
  'File types allowed for upload: .doc, .docx, .txt, .pdf, .csv, .png, .jpeg, .xls, .xlsx, .ics (apple calendar), .msg (Outlook email message).';
export const AH_ = 'Audit History -';
export const AUDT_BY = 'Audited By';
export const AUDT_RSLT = 'Audit Result';
export const ACTNS = 'Actions';
export const AUDT_DT = 'Audited Date';
export const F_NAME = 'File Name';
export const AUDT_ST = 'Audit Status';
export const NXT_AUDT_DT = 'Next Audit Date';
export const DYS_RMNG = 'Days remaining for the next Audit';
export const CDNS = 'Cadence';
export const ST_NAME = 'Site Name';
export const FL_EXT = 'File Extension';
export const OR_ = 'OR';
export const SRC = 'Search';
export const APLY = 'Apply';
export const SNMT = 'Submit';
export const STS = 'Status';
export const RMRKS = 'Remarks';
export const RGN = 'Region';
export const OTR_INFO = 'Other Information';
export const DTST_OVRV = 'Dataset Overview';
export const CNCL = 'Cancel';
export const UPDT = 'Update';

export const i18n = {
  APP_TITLE: 'Kamino',
  WELCOME: 'Welcome',
  SESSION_IA: 'Session Inactive',
  SESSION_ST: 'Session Timeout',
  SESSION_IDLE_DESC1:
    'Your session has been inactive. You will be logged out in',
  SESSION_DESC1: 'Your session has been Expired. You will be logged out in',
  SESSION_DESC2: 'seconds. Would you like to continue?',
  LBL_NDF: NDF,
  LBL_NSR: NSR,
  LBL_OK: 'Ok',
  LBL_NSRD: NSRD,
  LBL_DWN: DWNLOAD,
  LBL_MA: 'My Account',
  BTN_CANCEL: CNCL,
  BTW_SUBMIT: 'Submit',
  BTN_REMOVE: 'Remove',
  BTN_INVITE: 'Invite',
  BTN_UPDATE: UPDT,
  BTN_REFRESH: 'Refresh',
  BTN_PROCEED: 'Proceed',
  BTN_FREQ: 'Frequency',
  BTN_OD: 'On-Demand',
  BTN_YES: 'Yes',
  BTN_NO: 'No',
  BTN_ADD: 'Add',
  BTN_SELECT: 'Select',
  BTN_CLOSE: CLOSE,
  BTN_APPROVE: APPROVE,
  BTN_SEARCH: SRC,
  BTN_APPLY: APLY,
  BTN_RESET: 'Reset',
  BTN_CLEAR: 'Clear',
  BTN_LOGOFF: 'Log off',
  BTN_LOGOUT: 'Logout',
  LBL_MS: 'My settings',
  LBL_NS: 'Notifications',
  LBL_HELP: 'Help',
  LBL_NRFF: 'No results found for',
  LBL_RFF: 'results found for',
  NAV: {
    TC: '/u',
    LOBBY: '/m/applobby',
  },
  DATE: {
    SHORT: 'YYYY-MM-dd',
  },
  HEADER: {
    SP,
  },
  MENU: {
    HOME: 'Home',
    PUM,
    OM,
    PS: 'Settings',
    DC,
    DC_2LINES: `Data`,
    PM,
    SA,
  },
  BREADCRUMB: {
    HOME: 'Home',
    PUM,
    OM,
    PS: 'Settings',
    DC,
    PM,
    PM_H: 'Study Management - ',
    PD,
    COLL: 'Members',
    DI: 'Data Ingress',
    MA,
    DATA_CATALOG: DC,
    DD: DSD,
    DFO: 'Data Files Overview',
    CD,
    AP,
    EPD: 'Edit Study Details',
  },
  LINKS: {
    PR: 'Password Reset',
  },
  ICONS: {
    APPS: 'apps',
    PROFILE: 'account_circle',
    NN: 'navigate_next',
    SEARCH: 'search',
    CANCEL: 'cancel',
    BACK: 'arrow_back',
    CLOSE,
    DONE: 'done',
    DELETE: 'delete_outline',
    HELP: 'help_outline',
    HL_OFF: 'highlight_off',
    TICK_CIRCLE: 'check_circle_outline',
    CC: 'content_copy',
    DESC: 'description',
  },
  applobby: {
    LBL_PH: 'Placeholder',
    LBL_CNT_1: `Welcome to Kamino - pioneering the future of research, marked by innovative strategies and conscientious data usage.`,
    LBL_CNT_2: `Embodying an ethos of being 'as open as possible and as closed as necessary,' we facilitate a vibrant scientific
    community through our interactive and intuitive interface.
    Kamino is your comprehensive platform, aimed at spurring scientific breakthroughs while endowing researchers and institutions
    with complete control over their data lifecycle.`,
    LBL_CNT_3: `From data ingestion to analysis, publication, and reuse, Kamino streamlines and assures compliant collaboration at imCORE,
    prioritizing data security, confidentiality, and adherence to best practices.
    Through stringent governance and transparent processes, we present an accountable platform to our users and stakeholders.`,
    LBL_CNT_4: `Join us in advancing scientific understanding and marking a global impact. Explore Kamino today, and embark on a groundbreaking
     journey of research collaboration and disco.`,
    LBL_IL: 'Important Links',
    LBL_HEADING: 'HEADING',
    ILS: 'Important Link',
    LBL_SD: 'Kamino Documentation',
    BTN_GS: 'Get Started',
  },
  TNC: {
    TITLE: 'Terms and Conditions',
    LBL_SP: SP,
    DECLINE:
      'You have chosen to decline our terms and conditions, your session will be logged out.',
    BTN_DECLINE: 'Decline',
    BTN_ACCEPT: 'Accept',
    DES_TRAINING: 'Please complete your required training(s) to access Kamino',
    DES_ROLE: `You don't have access to any organization in Kamino. You can't proceed further. Please exit the application.`,
  },
  userManagement: {
    PUM,
    NDF,
    PAGINATION: RPP,
    BTN: {
      IU,
      EDIT: 'Edit',
      EU: 'Edit User',
      INVITE: 'Invite',
      IM,
    },
    inviteUser: {
      LBL_PN: PN,
      LBL_ON: ON,
      LBL_Username: 'Username (E-mail ID)',
      LBL_SPA: 'Kamino Platform Admin',
      LBL_PR: 'Study Role',
      LBL_FN: 'First Name',
      LBL_LN: 'Last Name',
      LBL_ORG: 'Organization',
      LBL_OR: 'Organization Role',
      BTN_CANCEL: CNCL,
      BTN_UPDATE: UPDT,
      ERR_MM: 'Please enter min 1 char, max 16 characters',
      ERR_SPL: 'Only these special characters allowed <space> - . _',
    },
    ApproveUser: {
      LBL_AU: 'Approve User',
      DES_Q:
        'The selected user will get access to imCORE. Are you sure, you want to continue?',
      LBL_REMARKS: RMRKS,
      BTN_CANCEL: CNCL,
      BTN_APPROVE: APPROVE,
    },
  },
  orgMnt: {
    //Organization Management
    TITLE: OM,
    HEADER: SP,
    PAGINATION: RPP,
    LBL_OV: 'Organization Overview',
    BTN_CREATE_ORG: 'Create Organization',
    LBL_NDF: NDF,
  },
  projectMnt: {
    //Project Management
    TITLE: PM,
    TITLE_UO: 'Users Overview',
    LBL_NDF: NDF,
    PAGINATION: RPP,
    TABS: {
      PO: 'Study Overview',
      UO: 'Users Overview',
    },
    ANALYTICS: {
      TITLE: 'Analytics',
      ST: 'Here are the analytics dashboard. Use it to check the latest activities and access selected tools',
      LBL_LAUNCH: 'Launch',
      LBL_STANDARD: 'Standard HPC',
      LBL_CA: 'Code Artifact',
      LBL_AWS: 'Connect to AWS from VS code/PyCharm',
      TXT_AWS:
        'Please copy the following keys and token before you close this page. You may not be able to view the same information again.',
      TXT_PR_URL: 'Public repository URL',
      TXT_SSR_URL: 'Study specific repository URL',
      BTN_GK: 'Generate Keys',
      AWS_AK: 'Access Key',
      AWS_SK: 'Secret Key',
      AWS_ST: 'Session Token',
      AWS_RN: 'AWS Region',
      PWD_STR: '********************',
      TERMINATE_TXT_START: 'By terminating',
      TERMINATE_TXT_END:
        'instance, you shut down this resource. You may lose the output data from your analysis if you have not saved. Are you sure you want to terminate?',
    },
    PO: {
      //Project Overview
      TITLE: 'Study Overview',
      BTN_ADD: AP,
      TOASTR_S: 'Study ID Copied Successfully',
      RP: {
        //Remove Project
        TITLE: 'Remove Study',
        DESC: `The selected study will be removed and the users will lose access to the study. Are you sure, you want to continue?`,
        TOASTR_S: 'Study removed from the organization successfully',
        BTN_CANCEL: CNCL,
        BTN_REMOVE: 'Remove',
      },
    },
    DO: {
      // Dataset List
      EMPTY_MESSAGE: {
        INGRESSED: NSR,
        PUBLISHED: NSR,
        WORK: 'There are no analytics',
        SHARED: NSR,
        SEARCH: NSR,
        DOWNLOADS: NSR
      },
    },
    PD: {
      // Project Details
      TITLE: PD,
      BTN_ED: 'Edit Details',
      SUB_TITLE_PI: STYI,
      SUB_TITLE_OI: OTR_INFO,
      PN,
      ON,
      R: RGN,
      ISD: 'imCORE Scientific Director',
      SN: ST_NAME,
      PD: P_DESC,
      DC: DCLAS,
      AB: 'Allow Publishing to Data Catalog',
      SAR: 'Set Audit Rule',
      SFAD: 'Set First Audit Date',
      SHARE_IMCORE: "Share with imCORE",
      ACCEPT_SHARING_TO_REPOSITORY: 'Would you, in principle, be willing to submit data generated in this\n' +
        '        study to the imCORE Data Repository for future secondary analyses by\n' +
        '        network members? Any data deposited in the iDR would be governed by the\n' +
        '        terms and conditions of the imCORE Data Use Agreement signed by your\n' +
        '        institution.',
      ADDITIONAL_STUDY_FILES: "Additional Study Files",
      ADD_IDEA_AND_CONTEXT: 'Easily add idea/concept and other study related files by dragging and\n' +
        '      dropping or uploading them individually.',
      META_DATA: 'Meta Data',
      IM_CORE_IDENTIFIER: 'imCORE Study Identifier',
      STATIC_CONTENT: '[Static content]'
    },
    UO: {
      // User Overview
      BTN_REMOVE: 'Remove',
      TOASTR_S: 'User removed from the organization successfully!',
      TOASTR_E: 'Something went wrong!',
      UD: {
        // User details
        BTN_CLOSE: CLOSE,
      },
      RU: {
        // Remove user
        TITLE: 'Remove User from imCORE',
        DESC: `The selected user will be removed from imCORE and all associated studies. Are you sure, you want to continue?`,
        BTN_CANCEL: CNCL,
        BTN_REMOVE: 'Remove',
      },
    },
    PF: {
      // Project file
      TABS: {
        PD,
        COLL: 'Members',
        DI: 'Data Ingress',
        MA,
      },
    },
    MA: {
      // My approvals
      TITLE: MA,
      MA_ST: 'These are all the datasets pending for your approval.',
      BTAA: 'Back to all approvals',
      RF: {
        //Reject File
        TITLE: 'Reject File',
        LBL_RR: 'Rejection Reason',
        BTN_CANCEL: CNCL,
        BTN_SUBMIT: SNMT,
      },
      DD: {
        // Dataset Details
        TITLE: DSD,
        LBL_DN: DSN,
        LBL_DD: DID,
        LBL_CB: CB,
        LBL_DC: DCD,
        LBL_DI: DING,
        LBL_PN: PN,
        LBL_ON: ON,
        LBL_DM: DMTY,
        LBL_SN: ST_NAME,
        LBL_DSD: DSDESC,
        LBL_DCL: DCLAS,
        LBL_NODF: NODF,
        LBL_DS: DSIZE,
        BTN_R: 'Reject',
        BTN_A: 'Accept',
        BTN_S: SNMT,
        BTN_C: CNCL,
        BTN_D: 'DONE',
        LBL_RFAR: 'Reason for Approval or Rejection',
        LBL_RDSFF: 'Review dataset folders and files',
        LBL_DSF: 'Dataset Files',
        LBL_DSC: 'Dataset Category',
        LBL_AD: 'About Dataset',
        LBL_RDS: 'Review the dataset submission',
        LBL_RDR: 'Review Dataset Request',
        LBL_BACKTOALLDATASETS: 'Back to all Datasets',
        LBL_AOR: 'Approve or Reject',
        LBL_DFAF: 'Dataset Folders and Files',
        LBL_DFAF_DESCRIPTION:
          'Review the files and folders and choose whether to accept or reject them.',
      },
    },
    collaborators: {
      TITLE: 'Members Overview',
      BTN_IC: 'Invite Members',
      BTN_R: 'Remove',
      BTN_EDIT: 'Edit',
      BTN_DONE: 'Done',
      BTN_UPDATE: 'Update',
      BTN_CANCEL: CNCL,
      RC: {
        TITLE: 'Remove Member',
        DESC1: 'The selected member will be removed from the study ',
        DESC2: 'Are you sure, you want to continue?',
      },
      DC: {
        DESC1: 'The selected user is the Data Controller for this study.',
        DESC2:
          'Transfer this role to another user using "Manage Data Controller" option in this Collaborators page before removing the user.',
      },
      DC_RM: {
        DESC1: 'You are removing Data Controller for this study.',
        DESC2:
          'Please assign Data Controller role to another user without fail.',
      },
      DC_UP: {
        TITLE: 'Member Role Update',
        DESC1: 'You are updating Data Controller for this study.',
        DESC2:
          'The selected user is the Data Controller for this Study. You cannot change the role unless you transfer ' +
          'Data Controller role to another user using "Manage Data Controller" option in Members page.',
      },
      IC: {
        TITLE: 'Invite Members',
        LBL_SU: 'Search Username (email ID)',
        LBL_SU_FIELD: 'Search for new Data Controller',
        LBL_UNF: 'Member not found',
        LBL_DESC: 'Click below to invite a new member',
        LBL_DESC2: 'No members selected. Select Members from the list.',
        BTN_IU: IM,
        LBL_NMU: 'No mapping member',
        LBL_CBNU: 'Click below to invite a new member',
        LBL_ON: ON,
        LBL_PN: PN,
        LBL_UE: 'Username (email ID)',
        LBL_FN: 'User Name',
        LBL_SR: 'Select Role',
        TOASTR_S: 'Member invited to the study successfully',
      },
      EC: {
        TOASTR_S: 'Member role updated successfully',
        TOASTR_E: 'Unable to update member role. Please try again.',
        TITLE: 'Edit Member',
        LBL_U: UN,
        LBL_ON: ON,
        LBL_PN: PN,
        LBL_CR: 'Current Role',
        LBL_SNR: 'Select New Role',
      },
      DelCollabUsers: {
        TOASTR_S: 'Member removed from study successfully',
        TOASTR_E: 'Member removal is not successful. Please try again.',
      },
    },
    DI: {
      // DATA INGRESS
      TITLE: DTST_OVRV,
      BTN_CDS: CD,
      DSD: {
        TITLE: DSD,
        LBL_DD: DSD,
        LBL_DN: DSN,
        LBL_DID: DID,
        LBL_CB: CB,
        LBL_DC: DCD,
        LBL_DI: DING,
        LBL_DDESC: DSDESC,
        LBL_SN: ST_NAME,
        LBL_PN: PN,
        LBL_ON: ON,
        LBL_DM: DMTY,
        LBL_DCLA: DCLAS,
        LBL_NODF: NODF,
        LBL_DS: DSIZE,
        LBL_F: 'Files',
        LBL_FN: F_NAME,
        LBL_FE: FL_EXT,
        LBL_S: STS,
      },
      DS: {
        // Dataset ingress
        TITLE: 'Data',
        SUB_TITLE: 'This is the data accessible to you for this study',
        SUB_TITLE2: 'These download datasets will be removed from this list 7 days after they become available for download.',
        DES_DND: 'Drag and drop files less than 1 GB',
        AD: 'Add Dataset',
        LINK_VT: 'View tutorial',
        LBL_OR: OR_,
        LBL_UF: 'upload folders',
        LBL_BF: 'browse files',
        LBL_SF: 'Supported Formats',
        STEPS: {
          //Steps
          STEP1: 'Upload Files to this Study ',
          STEP2: 'Add Meta Data',
          STEP3: 'Review Dataset',
        },
        DRAWER_UF: {
          TITLE: 'Uploaded Files',
          SUB_TITLE: 'Here are your uploaded files.',
        },
        DRAWER_FTP: {
          TITLE: 'Upload files with sFTP',
          DESC: `To connect to your sFTP (Secure File Transfer Protocol) copy the following URL and token into your sFTP application. The token (password) is valid for 24 hours.`,
          DESC1: 'We offer to install',
          DESC2: `if you don't have an FTP client`,
          LINK: 'FileZilla',
          HOST: 'sFTP URL port # (host)',
          TOKEN: 'Token (password)',
          PORT: 'Port',
          UN,
        },
        UFTP: {
          TITLE: 'Upload Files to this Study',
          DESC: `You can securely transfer your files using sFTP, simply drag and drop your files directly
            onto the page, upload them directly from your computer or select those files that you want
            to ingress from an existing dataset.`,
          GUIDE_T: 'Guidelines:',
          GUIDE_DESC: `
              1. Executable file type (.exe) is not permitted for uploading  <br/>
              2. Maximum number of files per upload is 100 files <br/>
              3. Total file size allowed per upload is 160 GB <br/>
              4. Maximum size allowed per file is 100 GB<br/>
              5. Do not click the browser's back or refresh buttons until all files are uploaded <br/>
              6. Do not sign out or close the browser window until all files are uploaded
            `,
          LINK: 'Upload with sFTP',
          LINK_2: ' for files larger than ',
          TSTR: 'Upload Started. Click on Show Upload Status to view the progress.',
          BTN_SMT: SNMT,
          BTN_NS: 'Next',
          BTN_B: 'Back',
          NDF: 'There are no available dataset files',
        },
        AMD: {
          TITLE: 'Add Meta Data',
          ST: 'Please provide metadata',
          UF,
          F: 'files',
          VF: 'View Files',
        },
        RDS: {
          TITLE: 'Review Dataset',
          ST: 'Please review and submit for approval',
          LBL_DSN: DSN,
          LBL_SN: 'Sitename',
          LBL_DDESC: 'Dataset description / purpose',
          LBL_DM: 'Data Modalities',
          LBL_UF: UF,
          LBL_DSS: 'Dataset Submitted',
          DESC: `The dataset has been sent to the project data controller for review. `,
          DESC2: 'You will be emailed once the review is complete.',
          BTN_AAD: 'Add Another Dataset',
          BTN_DONE: 'Done and back to Study',
        },
      },
    },
    DFL: {
      // Data Files List
      TOASTR_DFC_E:
        'Data files count is limited to 200 per dataset. Please review your selection',
      LBL_CD: CD,
      LBL_SFTP: 'sFTP Details',
      LBL_URL: 'sFTP URL',
      LBL_AK: 'Authentication Key',
      LBL_DFO: 'Data Files Overview',
      LBL_SH: 'Search here',
      DESC: `No data files found <br />After uploading the files, click on the refresh button below`,
    },
    CD: {
      //Create Dataset
      TITLE: CD,
      DN: DSN,
      LBL_ERR: 'Field cannot be empty',
      LBL_ERR1: 'Dataset Name must,',
      LBL_ERR2: 'Min length = 1 and Max length = 75 characters.',
      LBL_ERR3: 'Characters are alphanumeric',
      LBL_ERR4: 'hyphen (-) and underscore (_) special characters are allowed',
      LBL_DSDES: DSDESC,
      BTN_BTF: 'Back To Files',
      BTN_CD: CD,
    },
    CP: {
      // Create project
      TITLE_AP: AP,
      TITLE_EP: 'Edit study Details',
      LBL_PI: STYI,
      LBL_PN: PN,
      LBL_R: RGN,
      LBL_SN: ST_NAME,
      LBL_PD: P_DESC,
      LBL_OI: OTR_INFO,
      LBLDC: DCLAS,
      LBL_AP: 'Allow Publishing to Org Data Catalog *',
      LBL_SAR: 'Set Audit Rule *',
      LBL_SFAD: 'Set First Audit Date *',
      LBL_DC: DCLAS,
      ERR_PNM: 'Study Name must',
      ERR_2: 'It must start with an alphabetic character',
      ERR_3: 'Characters 2 to 5 should be alphanumeric',
      ERR_4:
        'Special characters are allowed beyond first *five* characters: + - = . _ : / @',
      ERR_5: 'Min length = *5* and Max length = *20*',
    },
    AP: {
      //Add Project
      STEPS: {
        //Steps
        STEP1: 'Study Idea',
        STEP2: 'Study Concept',
        STEP3: 'Compute Configuration',
        STEP4: 'Additional Files',
        STEP5: 'CITC Details',
        STEP6: 'Review Study Proposal',
      },
      PI: {
        //Project Idea
        PIN: {
          LBL: 'Study Name (Scientific Study Title)',
          ERR_1: 'The first 5 characters cannot be special characters',
          ERR_2: 'Study Name already exists',
          MAX: 100,
          MIN: 1,
        },
        REGION: {
          LBL: RGN,
        },
        PD: {
          LBL: P_DESC,
          ERR: AP_ERR_TYPE_1,
        },
        I: {
          LBL: 'Idea',
          ERR: AP_ERR_TYPE_1,
        },
        EA: {
          LBL: 'Email Address',
          ERR: AP_EMAIL_ERR,
        },
        LNPI: {
          LBL: 'First and last name of principal investigator (PI)',
          ERR: AP_ERR_TYPE_2,
        },
        LSI: {
          LBL: 'Lead Site/ Institution',
        },
        FNICA: {
          LBL: 'First and last name of other idea co author(s)',
          ERR: AP_ERR_TYPE_2,
        },
        LNICA: {
          LBL: 'First and last name of other idea co author(s)',
          ERR: AP_ERR_TYPE_2,
        },
        NCSAI: {
          LBL: 'Name(s) of Collaborating Site(s)/Institution(s)',
          ERR: 'Only alphanumeric and special characters -._/ are allowed',
          ERR_2: `Collaborating Site(s) can't be the same as Lead Site`,
        },
        DA: {
          LBL: 'Disease Area',
        },
        ST: {
          LBL: 'Study Type',
        },
        SDD: {
          LBL: 'Please describe the scientific question and rationale that will be answered with the study incl. essential information about study design',
          ERR: AP_ERR_TYPE_1,
          TOOLTIP:
            'Please apply (if possible) the PICO Framework: PICO: Population, Intervention, Control, Outcome(s)',
        },
        DD: {
          LBL: 'Drug Products that are being researched in the study, if applicable',
          ERR: AP_ERR_TYPE_1,
        },
        SD: {
          LBL: 'Who have you discussed the idea with from Roche already? Names of (study) affiliated Roche Scientists',
          ERR: AP_ERR_TYPE_1,
        },
        SI: {
          LBL:
            'Would you, in principle, be willing to submit data generated in this study to the imCORE Data Repository for future secondary analyses by network members?  ' +
            'Any data deposited in the iDR would be governed by the terms and conditions of the imCORE Data Use Agreement signed by your institution.',
        },
        IF: {
          LBL: 'Is your IDEA submitted in response to the "Immune Fitness" RFP?',
        },
        RFP: {
          LBL: 'Which RFP or event generated this study, if any?',
          ERR: AP_ERR_TYPE_1,
        },
        SE: {
          LBL: 'Please fill in your specific expertise.',
          ERR: AP_ERR_TYPE_1,
        },
        SIF: {
          LBL: 'Additional Information supporting the IDEA (i.e. a document with graphics)',
          INFO_1: AP_INFO_1,
          INFO_2: AP_INFO_2,
          INFO_3: AP_INFO_3,
          INFO_4: AP_INFO_4,
          INFO_5: AP_INFO_5,
          INFO_6: AP_INFO_6,
        },
        StudyDesign: {
          Label: 'Upload a study design document',
          StudyDesignFile: 'Study Design File'
        },
        ISI: {
          LBL: 'imCORE Study Identifier',
        },
        MDMS: {
          LBL: 'MDMS Study Identifier',
          ERR: AP_ERR_TYPE_3,
        },
        STATUS: {
          LBL: STS,
          ERR: AP_ERR_TYPE_3,
        },
        REMARK: {
          LBL: RMRKS,
          ERR: AP_ERR_TYPE_1,
        },
        CSD: {
          LBL: 'Current Status details',
        },
        SARM: {
          LBL: 'sARM',
          ERR: AP_ERR_TYPE_3,
        },
        COMMUNI: {
          LBL: 'Recent communication to PI',
        },
        YOS: {
          LBL: 'Year of Submission',
        },
      },
      PC: {
        //Project concept
        ISCT: {
          LBL: 'Is the Idea endorsed by iScT?',
        },
        ENDORESDATE: {
          LBL: 'Date of Endorsement',
        },
        COMMENT: {
          LBL: CMTS,
          ERR: AP_ERR_TYPE_1,
        },
        ATTACH: {
          LBL: 'Attachments',
          INFO_1: AP_INFO_1,
          INFO_2: AP_INFO_2,
          INFO_3: AP_INFO_3,
          INFO_4: AP_INFO_4,
          INFO_5: AP_INFO_5,
          INFO_6: AP_INFO_6,
        },
        ORWR: {
          LBL: 'Override with Reason',
          ERR: AP_ERR_TYPE_1,
        },
      },
      CC: {
        //Compute Configuration
        CC: {
          LBL: 'Compute Configuration',
          COST_CATEGORIES: 'Cost Categories',
          COMPUTE_ALERTS: 'Compute Budget Alerts',
        },
        CC_INPUT_LABELS: {
          TOTAL_COMPUTE_COST: 'Total Compute Cost',
          ALERT_1: '1st Alert Threshold',
          ALERT_2: '2nd Alert Threshold'
        },
        CC_INPUT_DESCRIPTIONS: {
          TOTAL_COMPUTE_COST: 'This is the total cost of compute services',
          TOTAL_COMPUTE_COST_SUMMARY:'This is the total compute cost for the entire study.',
          COMPUTE_BUDGET_ALERTS_DESCRIPTION: 'Those user with access will receive an email alert when compute costs reaches the alert threshold',
          ALERT_1: 'Compute Cost Alert Threshold',
          ALERT_2: 'Compute Cost Alert Threshold',
          ALERT_1a: '1st Compute Cost Alert Threshold',
          ALERT_2a: '2nd Compute Cost Alert Threshold'
        },
        CC_INPUT_ERRORS:{
          FIRST_GREATER_THAN_SECOND:`1st Alert Threshold cannot be greater than 2nd Alert Threshold`,
          TOTAL_COMPUTE_COST_ERROR:`Field cannot be empty and has to be a number.`,
          TOTAL_ALERT_ERROR:`Field cannot be empty and has to be a percentage value between 1-100.`

        },
        CC_CONFIRMATION_MODAL:{
          TITLE:'Cancel manage budget details?',
          TEXT:'After cancellation, all changes will be lost',
        }
      },
      AF: {
        //Additional Files
        BD: {
          LBL: 'Budget Document',
          INFO_1: AP_INFO_1,
          INFO_2: AP_INFO_2,
          INFO_3: AP_INFO_3,
          INFO_4: AP_INFO_4,
          INFO_5: AP_INFO_5,
          INFO_6: AP_INFO_6,
        },
        OA: {
          LBL: 'Other Attachments',
          INFO_1: AP_INFO_1,
          INFO_2: AP_INFO_2,
          INFO_3: AP_INFO_3,
          INFO_4: AP_INFO_4,
          INFO_5: AP_INFO_5,
          INFO_6: AP_INFO_6,
        },
      },
      CD: {
        //CITC Details
        ISCT: {
          LBL: 'Is the study endorsed by CITC?',
        },
        ENDORESDATE: {
          LBL: 'Date of Endorsement',
        },
        COMMENT: {
          LBL: CMTS,
          ERR: AP_ERR_TYPE_1,
        },
        ATTACH: {
          LBL: ATMTS,
          INFO_1: AP_INFO_1,
          INFO_2: AP_INFO_2,
          INFO_3: AP_INFO_3,
          INFO_4: AP_INFO_4,
          INFO_5: AP_INFO_5,
          INFO_6: AP_INFO_6,
        },
        ORWR: {
          LBL: 'Override with Reason',
          ERR: AP_ERR_TYPE_1,
        },
      },
      RPP: {
        //Review Project Proposal
        SUB_HEAD_1: '1.Review Summary',
        SUB_HEAD_2: ' Add Additional Email Address',
        EMAIL: {
          LBL: 'Email Address (Maximum of 20 Email Address)',
          ERR1: AP_EMAIL_ERR,
          ERR2: 'Email should not be more than 20.',
        },
      },
      TITLE: 'Propose Study',
      ERR_EMPTY: 'Field cannot be empty',
      CANCEL_BTN: CNCL,
      ENTER_STUDY_DESCRIPTION: 'Enter a description for this study',
      SCIENTIFIC_RATIONALE: 'Scientific rationale',
      SCIENTIFIC_RATIONALE_DESCRIPTION: '  Please describe the scientific question and rationale that will be\n' +
        '        answered with the study incl. essential information about study design.\n' +
        '        Please apply (if possible) the PICO Framework: PICO: Population,\n' +
        '        Intervention, Control, Outcome(s)',
      SAE_BTN: 'Save and Exit',
      NXT_BTN: 'Next',
      PRE_BTN: 'Previous',
      OPTIONAL: 'optional',
      APPROVE,
      DWL: DWNLOAD,
      TRANSFER: 'Transfer',
      TRP: {
        //TransfterProject
        TITLE: 'Study Transfer',
        RD: {
          LBL: '2.Review Details',
          SUMMARY: `Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt
            Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation
            Ullamco Laboris Nisi Ut Aliquip Ex Ea Commodo Consequat.Lorem Ipsum Dolor Sit Amet,
            Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt
            Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation
            Ullamco Laboris Nisi Ut Aliquip Ex Ea Commodo Consequat.`,
          ACCEPT: 'I Accept',
          TAC: `Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit,
            Sed Do Eiusmod Tempor
            Incididunt
            Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation
            Ullamco Laboris Nisi Ut Aliquip Ex Ea Commodo Consequat.`,
        },
        DC: {
          LBL: '3.Select Data Controller',
          FIELD_1: 'Current Data Controller',
          FIELD_2: 'Search for new Data Controller (email ID)',
        },
      },
    },
  },
  StudyAudit: {
    //Study Audit
    TBL_MANAGE_USERS_TITLE: MANAGE_MEMBERS,
    TITLE: SA,
    TBL_TITLE: SA,
    TBL_HEADER: {
      HDR_PN: STYN,
      HDR_AS: AUDT_ST,
      HDR_LAD: LAD,
      HDR_NAD: NXT_AUDT_DT,
      HDR_DR: DYS_RMNG,
      HDR_CD: CDNS,
      HDR_ATN: ACTNS,
    },
    TABS: {
      TAB_SAR: START_DOCUMENT_RESULT,
      TAB_PI: STYI,
      TAB_MU: 'Manage Members',
      TAB_AH: AH,
    },
    LBL_MDC: MDC,
    BTN_ASSIGN: ASGN,
    SI: {
      DWL: DWNLOAD,
      DWL_REPORT: 'Download Report',
      SDR: 'Select Date Range',
    },
  },
  catalogManagement: {
    //Catalog Management
    TITLE: DTST_OVRV,

    SEARCH_BUTTON: SRC,
    ADD_BUTTON: 'Add',
    RESET_BUTTON: 'Reset',
    CANCEL_BUTTON: CNCL,
    APPLY_BUTTON: APLY,
    CLEAR_BUTTON: 'Clear',
    LBL_CND: 'Condition',
    NRF: 'No results found',
    LBL_V: 'Value',
    LBL_SD: 'Select date',
    LBL_SDR: 'Select Date range',
    LBL_AND: 'AND',
    LBL_OR: OR_,
    LBL_EQLTO: 'equals to',

    DATASET_DETAILS: {
      // Dataset Details
      TITLE: 'Dataset Metadata Details',
      LBL_DN: DSN,
      LBL_DD: DID,
      LBL_CB: CB,
      LBL_DC: DCD,
      LBL_DI: DING,
      LBL_PN: STYN,
      LBL_ON: ON,
      LBL_DM: DMTY,
      LBL_SN: ST_NAME,
      LBL_DSD: DSDESC,
      LBL_DCL: DCLAS,
      LBL_NODF: NODF,
      LBL_DS: DSIZE,
      LBL_DF: 'Data Files',
      LBL_FN: F_NAME,
      LBL_FE: FL_EXT,
    },
  },
  projectAudit: {
    //Project Audit
    TITLE: 'Study Audit',
    TBL_TITLE: 'Study Audit Overview',
    TBL_MANAGE_USERS_TITLE: MANAGE_MEMBERS,
    TBL_HEADER: {
      HDR_PN: PN,
      HDR_AS: AUDT_ST,
      HDR_LAD: LAD,
      HDR_NAD: NXT_AUDT_DT,
      HDR_DR: DYS_RMNG,
      HDR_CD: CDNS,
      HDR_ATN: ACTNS,
    },
    TABS: {
      TAB_SAR: START_DOCUMENT_RESULT,
      TAB_PI: STYI,
      TAB_MU: MANAGE_MEMBERS,
      TAB_AH: AH,
    },
    LBL_MDC: MDC,
    BTN_ASSIGN: ASGN,
    TAB_SAR_CONTENT: {
      TITLE: START_DOCUMENT_RESULT,
      H_PN: STYN,
      H_AID: AUD_I,
      H_ACBD: CURR_AUD,
      H_LAD: LAD,
      H_CFA: CAD_AUD,
      H_SND: NO_DAYS,
      BTN_SA: ST_AUD,
      BTN_AR: DOCATRES,
    },
    DAR_POPUP: {
      TITLE: DOCATRES,
      FF_TITLE_DAR: DOCATRES,
      FF_TBTN_AP: A_P,
      FF_TBTN_PWW: PWW,
      FF_TITLE_CMT: CMTS,
      FF_TITLE_ARN: ADD_EMAIL_TONOTIFY,
      FF_ARN_ERR_M_COUNT: EMAIL_20,
      FF_ARN_ERR_M_VALID: VALID_EMAIL,
      FF_TITLE_ATC: ATMTS,
      TXT_DD: DRAG_DROP,
      TXT_OR: OR,
      BTN_BF: BROWSE_FILES,
      TXT_GDL_UP: AP_INFO_1,
      TXT_GDL_1: MAX_20,
      TXT_GDL_2: SZ_LIMIT,
      TXT_GDL_3: ATMTS_SZ,
      TXT_GDL_4: FILE_TYPE,
    },
    TAB_AH_CONTENT: {
      TITLE: AH_,
      TBL_HDR_LAD: LAD,
      TBL_HDR_ABY: AUDT_BY,
      TBL_HDR_AR: AUDT_RSLT,
      TBL_HDR_ATN: ACTNS,
    },
    AR_POPUP: {
      TITLE_DAR: DOCATRES,
      TITLE_AD: AUDT_DT,
      TITLE_CMT: CMTS,
      TITLE_AEN: ADD_EMAIL_TONOTIFY,
      TITLE_ATCH: ATMTS,
      TBL_HDR_FN: F_NAME,
    },
  },
  errors: {
    UA: '401',
    NF: '404',
    UF: '403',
    SE: '500',
  },
  // Inbox Management
  INBOX_MANAGEMENT: {
    DETAILS: {
      PUBLISHING: {
        TITLE_OVERVIEW: 'Review Dataset for Catalogue',
        TITLE_FILELIST: 'Data files to be Published',
      },
      DATA_ACCESS: {
        TITLE: 'Review Request to Access',
        PROVIDE_REASON: {
          REJECTED: {
            TITLE: 'Provide Reason of Rejection',
            SUB_TITLE: 'Enter a reason for the rejection of this request',
            PLACEHOLDER: 'Rejection Reason',
            ASSISTIVE_TEXT: 'Enter a reason for the rejection',
          },
          APPROVED: {
            TITLE: 'Provide Reason of Approval',
            SUB_TITLE:
              'Enter an optional reason for the approval of this request',
            PLACEHOLDER: 'Approval Reason (optional field)',
            ASSISTIVE_TEXT: 'Enter an optional reason for this approval',
          },
        },
      },
    },
    STATUS_DESCRIPTION: {
      'Pending approval': 'need your review',
      Approved: 'you have approved',
      Rejected: 'you rejected'
    },
    CONFIRMATION_DIALOG: {
      CANCEL: {
        TITLE: 'Cancel review?',
        MESSAGE: 'Cancelling the review will not save your changes',
        CONFIRM_BTN: 'Yes',
        REJECT_BTN: 'No',
        REQUEST: {
          TITLE: 'Cancel review?',
          MESSAGE:
            'After cancellation, the request will stay on the list of pending approval',
          CONFIRM_BTN: 'Yes',
          REJECT_BTN: 'No',
        },
      },
      REJECT: {
        TITLE: 'Reject this dataset?',
        MESSAGE: 'Are you sure you want to reject this dataset?',
        CONFIRM_BTN: 'Yes',
        REJECT_BTN: 'No',
      },
      REJECT_ACCESS: {
        TITLE: 'Reject this request?',
        MESSAGE: 'Are you sure you want to reject this request?',
        CONFIRM_BTN: 'Yes',
        REJECT_BTN: 'No',
      }
    },
    NOTIFY: {
      BUTTON: 'Done',
    },
  },
};
