<div class="confirm-popup">
  <owc-modal-dialog visible="visible" size="small" disable-backdrop-click={false} disable-escape-keydown={false}
    disable-scroll-lock={false}>
    <owc-component-header slot="header" class="header-title">{{data.title}}</owc-component-header>
    <owc-card class="confirm-popup-card">
      {{data.confiramtion_text}}
    </owc-card>
    <owc-component-footer slot="footer">
      <div slot="suffix" class="footer-suffix-wrapper">
        <owc-button variant="secondary" (click)="onDecline()">{{data.decline_btn}}</owc-button>
        <owc-button (click)="onAccept()">{{data.accept_btn}}</owc-button>
      </div>
    </owc-component-footer>
  </owc-modal-dialog>
</div>